/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { Box, Grid, GridItem, Heading, ListIcon, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { AiFillSafetyCertificate } from 'react-icons/ai';
import { Fade } from 'react-awesome-reveal';

const QualityPromise = () => (
  <Box
    id='quality-promise'
    as='section'
    className='section our-proposal'
    maxW={{ base: 'xl', md: '7xl' }}
    mx='auto'
    mt={{ base: 20, md: '150px' }}
    px={{ base: '6', md: '8' }}
  >
    <Box className='shapes-container' zIndex={-1}>
      <Fade direction='up' delay={300}>
        <div className='shape shape-circle pattern-dots' />
      </Fade>
    </Box>

    <Heading as='h3' mb={6} size={{ base: 'md', md: 'lg' }} className='heading-line'>
      The Vitamin Quality Assurance
    </Heading>
    <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={20} flexDirection='column-reverse'>
      <GridItem>
        <Text fontSize='xl' color='gray.500' mb={4}>
          Our vitamin profiles have been formulated by dietitians and fully researched and trialled by qualified
          pharmaceutical scientists.
        </Text>
        <Text fontSize='xl' color='gray.500'>
          The research and testing teams make use of the highest-grade ingredients, high tech analytical instruments and
          latest manufacturing techniques when producing and testing the vitamins for quality.
        </Text>
      </GridItem>
      <GridItem className='bring-to-front'>
        <Text fontSize='xl' color='gray.500' mb={6}>
          All our vitamins are manufactured in a category A laboratory that is registered with:
        </Text>
        <UnorderedList color='white' listStyleType='none' fontSize='md'>
          <ListItem color='gray.500'>
            <ListIcon as={AiFillSafetyCertificate} color='orange.500' />
            South African Pharmacy Council
          </ListItem>
          <ListItem color='gray.500'>
            <ListIcon as={AiFillSafetyCertificate} color='orange.500' />
            South African Health Products Regulatory Authority (SAHPRA)
          </ListItem>
          <ListItem color='gray.500'>
            <ListIcon as={AiFillSafetyCertificate} color='orange.500' />
            US Food and Drug Administration (FDA)
          </ListItem>
          <ListItem color='gray.500'>
            <ListIcon as={AiFillSafetyCertificate} color='orange.500' />
            Health Products Association (HPA)
          </ListItem>
          <ListItem color='gray.500'>
            <ListIcon as={AiFillSafetyCertificate} color='orange.500' />
            Selfcare Association (SCA)
          </ListItem>
          <ListItem color='gray.500'>
            <ListIcon as={AiFillSafetyCertificate} color='orange.500' />
            Cosmetic, Toiletry & Fragrance Association (CTFA)
          </ListItem>
        </UnorderedList>
      </GridItem>
    </Grid>
  </Box>
);

export default QualityPromise;
